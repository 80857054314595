import React from 'react';
import {FileAddOutlined} from '@ant-design/icons';


const File = () => {
  return (
    <div>
        file
    </div>
  );
};


const Upload = (props) => (
  <div
    className={'uploadfile__wrapper'}
    {...props}
  >
    <div><FileAddOutlined style={{fontSize: 22}} /></div>
  </div>
);

File.Upload = Upload;


export {File};
