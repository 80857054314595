/* eslint-disable max-len */
import {Input, Modal, notification, Form} from 'antd';
import {postAddNewAdmin} from 'api/employee';
import React, {useState} from 'react';

export const UserMakeAdmin = ({open, setOpen}) => {/* в open лежит ID юзера */
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm(); /* ссылка на форму */

  const handleClose = () => {
    setOpen(null);
  };

  const adminSuccessText = open?.changePassword ? 'Пароль администратора изменён' : 'Администратор добавлен';

  const handleOk = () => {
    form.validateFields()/* Валидация полей */
        .then((values) => {/* Если ок, то запрос */
          setLoading(true);
          postAddNewAdmin({employeeId: open.id, password: values.password})
              .then(() => {/* Если ок запрос, то обновляем таблицу и чистим поля */
                form.resetFields();
                setLoading(false);
                notification.success({message: adminSuccessText});
                handleClose();
              })
              .catch(() => {/* Иначе ошибка */
                setLoading(false);
                notification.error({message: 'Ошибка сервера'});
              });
        })
        .catch((info) =>{
          notification.error({message: 'Произошла ошибка'});
        });
  };

  return (
    <Modal
      title={`${open?.changePassword ? 'Сменить пароль' : 'Назначить'} администратора`}
      visible={open}
      onCancel={handleClose}
      onOk={handleOk}
      cancelButtonProps={{disabled: loading}}
      okButtonProps={{disabled: loading, htmlType: 'submit'}}
      okText='Сохранить'
    >
      <Form
        form={form}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        scrollToFirstError
        layout="vertical"
        size={'middle'}
      >
        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!',
            },
            {
              min: 6,
              message: 'Минимум 6 символов',
            },
            {
              max: 25,
              message: 'Максимум 25 символов',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Подтвердите пароль"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Обязательное поле!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
