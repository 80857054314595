/* eslint-disable max-len */
import React, {useMemo, useRef} from 'react';
import Table from 'antd/lib/table';
import * as dayjs from 'dayjs';
import {dateformat} from '_constants';
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';
import {
  blue,
  cyan,
  lime,
  magenta,
  purple,
  red,
  volcano,
} from '@ant-design/colors';
import {groupBy} from 'lodash';
import {Card} from 'antd';

export const ResultsAnswers = ({result}) => {
  const answersTotal = result.answers.length;
  const answeredCount = result.answers.filter((ans) => ans.answerId).length;

  const groupedData = useMemo(() => {
    const groupedAnswers = groupBy(result.answers, (answer) => answer.answerId);

    const lengthByAnswerId = Object.entries(groupedAnswers)
        .reduce((acc, [key, value]) => {/* output: {1: 12, 2: 4, .....} */
          if (key && key !== 'null') {
            acc[key] = value.length;
          }
          return acc;
        }, {});

    return Object.entries(lengthByAnswerId)
        .reduce((acc, [key, value]) => {
          const currentAnswer = result.notification.answers
              .find((a) => String(a.id) === String(key));
          acc.push({name: currentAnswer.name, value});
          return acc;
        }, []);
  }, []);

  const COLORS = useRef([
    red.primary,
    lime.primary,
    cyan.primary,
    blue.primary,
    purple.primary,
    magenta.primary,
    volcano.primary,
  ]);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 32,
      }}
    >
      <div
        style={{
          marginLeft: '-8px',
          marginRight: 8,
          width: '50%',
        }}
      >
        <Table
          dataSource={result.answers}
          columns={[
            {
              title: 'Дата',
              render: (_, record) => dayjs(record.date).format(dateformat),
              key: 'name',
            },
            {
              title: 'Сотрудник',
              render: (_, record) => record.employee.name,
              key: 'employee',
            },
            {
              title: 'Ответ',
              render: (_, record) => {
                return (
                  result.notification.answers
                      .find((ans) => ans.id === record.answerId)?.name || ''
                );
              },
              key: 'answer',
            },
          ]}
        />
      </div>
      <div
        style={{
          marginRight: '-8px',
          marginLeft: 8,
          width: '50%',
          minHeight: 276,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Card
          title={answersTotal ? `Ответов ${answeredCount || '0'}/${answersTotal || '0'}` : 'Нет ответов'}
          style={{height: '100%'}}
          bodyStyle={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <div>
            {groupedData.map((ans, index) => (
              <div
                key={ans.name}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  marginTop: 8,
                }}
              >
                <div style={{
                  height: 20,
                  minHeight: 20,
                  minWidth: 20,
                  width: 20,
                  borderRadius: '100%',
                  backgroundColor: COLORS.current[index % COLORS.current.length],
                  marginRight: 8,
                }} />
                <div>
                  {ans.name}
                </div>
              </div>
            ))}
          </div>
          <div style={{width: 250, height: 163}}>
            <ResponsiveContainer>
              <PieChart
                margin={{top: 0}}
              >
                <Pie
                  data={groupedData}
                  cx={'50%'}
                  cy={80}
                  innerRadius={50}
                  outerRadius={80}
                  paddingAngle={5}
                  label
                  dataKey="value"
                >
                  {groupedData.map((entry, index) => (
                  // eslint-disable-next-line max-len
                    <Cell key={`cell-${index}`} fill={COLORS.current[index % COLORS.current.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>

    </div>
  );
};
