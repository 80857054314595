/* eslint-disable no-unused-vars */
import axios from 'axios';

const prod = 'https://t.inbis-gua.ru/';
const dev = 'http://dashboard.inbis.api.famedev.ru/';

export const instance = axios.create({
  withCredentials: true,
  baseURL: prod,
});

instance.interceptors.request.use(function(config) {
  // Do something before request is sent
  // eslint-disable-next-line max-len
  config.headers['Authorization'] = 'Bearer' + ' ' + (localStorage.getItem('token') || '');
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error);
});
