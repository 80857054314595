import React from 'react';
import {
  FileOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {Spin} from 'antd';
import {red} from '@ant-design/colors';

export const SingleFile = ({
  file,
  isLoading,
  onDelete,
  onSelect,
}) => {
  const handleClick = (currentFile) => (e) => {
    onSelect && onSelect(currentFile);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete && onDelete(file);
  };

  if (isLoading) {
    return <Spin style={{width: 80}} />;
  }

  return (
    <div
      className='file__wrapper'
      onClick={handleClick(file)}
    >
      <div className="file__icon" >
        <FileOutlined style={{fontSize: 22}} />
      </div>
      <span className={'file__wrapper-filename'}>{file.fileName}</span>
      {onDelete && (
        <div className={'file__delete'} onClick={handleDelete} >
          <div>
            <CloseCircleOutlined style={{
              fontSize: 18,
              color: red.primary,
            }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
