import {cyan, green, gold, red} from '@ant-design/colors';
import {Button, Space, Popconfirm, Divider} from 'antd';
import {deleteUser} from 'api/employee';
import {
  SendNoificationModal,
} from 'Components/SendNotificationModal/SendNotificationModal';
import {UserMakeAdmin} from 'Components/UserModification/UserMakeAdmin';
import {UserModification} from 'Components/UserModification/UserModification';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {MyTable} from 'UI/Table';
import {Title} from 'UI/Title';
import {
  NotificationOutlined,
  EditOutlined,
  CrownOutlined,
  RedoOutlined,
  DeleteOutlined,
} from '@ant-design/icons';


const Users = () => {
  const [open, setOpen] = useState(false);
  const [makeAdminId, setMakeAdminId] = useState(null);
  const [structureToMerge, setStructureToMerge] = useState(0);
  const history = useHistory();
  const tableRef = useRef(null);

  const handleOpen = (id) => () => {
    setStructureToMerge({target: {'group': [], 'employees': [id]}});
    setOpen(true);
  };

  const handleDeleteUser = (user) => async () => {
    await deleteUser(user);
    tableRef.current.getData();
  };

  const renderActions = (text, record) => (
    <Space size={8} split={<Divider type="vertical" />}>
      <Button
        type='primary'
        shape='circle'
        onClick={handleOpen(record.id)}
        icon={<NotificationOutlined />}
      >
        {/* Уведомление */}
      </Button>
      <Button
        type='primary'
        style={{background: cyan.primary, border: cyan.primary}}
        shape={'circle'}
        icon={<EditOutlined />}
        onClick={() => history.push({
          pathname: `/users/${record.id}`,
          search: history.location.search,
        }, {employee: record})}
      >
        {/* Редактировать */}
      </Button>
      {!record.isAdmin && (
        <Button
          type='primary'
          style={{background: green.primary, border: green.primary}}
          onClick={() => setMakeAdminId({id: record.id, changePassword: false})}
          shape={'circle'}
          icon={<CrownOutlined />}
        >
          {/* + Администратор */}
        </Button>
      )}
      {record.isAdmin && (
        <Button
          type='primary'
          style={{background: gold.primary, border: gold.primary}}
          onClick={() => setMakeAdminId({id: record.id, changePassword: true})}
          shape={'circle'}
          icon={<RedoOutlined />}
        >
          {/* Смена пароля */}
        </Button>
      )}
      <Popconfirm
        title={'Удалить пользователя?'}
        onConfirm={handleDeleteUser(record)}
      >
        <Button
          type='primary'
          style={{background: red.primary, border: red.primary}}
          shape={'circle'}
          icon={<DeleteOutlined />}
        >
          {/* Удалить */}
        </Button>
      </Popconfirm>
    </Space>
  );

  const renderTelegram = (text, record) =>
    record.telegramNick || record.telegramID;

  return (
    <div>
      <Title>
        Сотрудники
      </Title>

      <MyTable
        ref={tableRef}
        columns={[
          {
            title: 'Телеграм',
            key: 'telegramID',
            render: renderTelegram,
          },
          {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Почта',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Действие',
            key: 'action',
            render: renderActions,
          },
        ]}
        endpoint='/dashboard/employees'
        dataField='results'
      />

      <SendNoificationModal
        structureToMerge={structureToMerge}
        open={open}
        setOpen={setOpen}
      />

      <UserModification tableRef={tableRef} />
      <UserMakeAdmin open={makeAdminId} setOpen={setMakeAdminId} />

    </div>
  );
};

export default Users;
