import {instance} from 'instance';

export const editEmployee = async ({id, ...body}) =>
  instance.post(`/dashboard/employees/${id}/edit`, {...body, id});

export const postAddNewAdmin = async (data) =>
  instance.post('/dashboard/employees/admin/add', data);

export const deleteUser = async (user) =>
  instance.delete(`/dashboard/employees/${user.id}`);
